"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
// 开发环境
var _default = exports.default = {
  base: {
    ip: window.location.href.indexOf('wms.cchexin.com') > -1 ? process.env.VUE_APP_BASE_APIw : process.env.VUE_APP_BASE_API
  },
  basic: {},
  storage: {
    ip: window.location.href.indexOf('wms.cchexin.com') > -1 ? process.env.VUE_APP_STORAGE_APIw : process.env.VUE_APP_STORAGE_API
  },
  auth: {
    ip: window.location.href.indexOf('wms.cchexin.com') > -1 ? process.env.VUE_APP_AUTHSERVER_APIw : process.env.VUE_APP_AUTHSERVER_API
  },
  crm: {
    ip: 'http://localhost'
  },
  oms: {
    ip: 'http://localhost'
  },
  wx: {
    ip: 'http://localhost',
    basicPort: ''
  },
  client: {
    client_id: 'basic-web',
    grant_type: 'password',
    scope: 'WebAppGateway BaseService BusinessService BaseData SalesManagement'
  }
}; // 测试环境
// export default {
// }
// 发布环境
// export default {
// }
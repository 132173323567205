"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _axios = _interopRequireDefault(require("axios"));
var _elementUi = require("element-ui");
var _store = _interopRequireDefault(require("@/store"));
var _auth = require("@/utils/auth");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
// create an axios instance

var service = _axios.default.create({
  // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  timeout: 600000 // request timeout
});

// request interceptor
service.interceptors.request.use(function (config) {
  if (_store.default.getters.token) {
    // let each request carry token
    // ['X-Token'] is a custom headers key
    // please modify it according to the actual situation
    config.headers['X-Token'] = (0, _auth.getToken)();
    config.headers['Content-Type'] = 'application/json';
    config.headers['wrap-result'] = true;
    // switch (config.system) {
    //   case 'sales':
    //     config.baseURL = process.env.VUE_APP_SALES_API
    //     break;

    //   case 'production':
    //     config.baseURL = process.env.VUE_APP_PRODUCTION_API
    //     break;

    //   case 'test':
    //     config.baseURL = process.env.VUE_APP_TEST_API
    //     break;

    //   case 'storage':
    //     config.baseURL = process.env.VUE_APP_STORAGE_API
    //     break;

    //   default:
    //     config.baseURL = process.env.VUE_APP_BASE_API
    // }

    if (window.location.href.indexOf('wms.cchexin.com') > -1) {
      switch (config.system) {
        case 'sales':
          config.baseURL = process.env.VUE_APP_SALES_APIw;
          break;
        case 'production':
          config.baseURL = process.env.VUE_APP_PRODUCTION_APIw;
          break;
        case 'test':
          config.baseURL = process.env.VUE_APP_TEST_APIw;
          break;
        case 'storage':
          config.baseURL = process.env.VUE_APP_STORAGE_APIw;
          break;
        case 'WatermarkOperation':
          config.baseURL = 'https://file.service.cchexin.com';
          break;
        default:
          config.baseURL = process.env.VUE_APP_BASE_APIw;
      }
    } else {
      switch (config.system) {
        case 'sales':
          config.baseURL = process.env.VUE_APP_SALES_API;
          break;
        case 'production':
          config.baseURL = process.env.VUE_APP_PRODUCTION_API;
          break;
        case 'test':
          config.baseURL = process.env.VUE_APP_TEST_API;
          break;
        case 'storage':
          config.baseURL = process.env.VUE_APP_STORAGE_API;
          break;
        case 'WatermarkOperation':
          config.baseURL = 'https://file.service.cchexin.com';
          break;
        default:
          config.baseURL = process.env.VUE_APP_BASE_API;
      }
    }
  } else {
    config.headers['wrap-result'] = true;
  }
  return config;
}, function (error) {
  // do something with request error
  return Promise.reject(error);
});

// response interceptor
service.interceptors.response.use(
/**
 * If you want to get http information such as headers or status
 * Please return  response => response
*/

/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code
 */
function (response) {
  var res = response.data;
  // if the custom code is not 20000, it is judged as an error.
  // if (res.code == 403) {

  //   Message({
  //     message: res.code || 'Error',
  //     type: 'error',
  //     duration: 5 * 1000
  //   })
  //   return Promise.reject(new Error(res.code || 'Error'))
  // } else {
  //   return res
  // }
  if (res.status == 200) {
    if (res.success) {
      return res.response;
    } else {
      (0, _elementUi.Message)({
        message: res.msg,
        type: 'error',
        duration: 5 * 1000
      });
      return Promise.reject(new Error(res.msg));
    }
  }
}, function (error) {
  if (error.response) {
    (0, _elementUi.Message)({
      message: error.response.data.error.code || error.response.data.error.message,
      type: 'error',
      duration: 5 * 1000
    });
    return Promise.reject(error);
  }
});
var _default = exports.default = service;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
var _default = exports.default = {
  name: 'App',
  mounted: function mounted() {
    window.addEventListener("message", function (event) {
      if (event.data) {
        // 将 token 存储到 localStorage 或 cookie 中
        localStorage.setItem("token", JSON.stringify(event.data));
      }
    }, false);
  }
};
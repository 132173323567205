"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
exports.getLanguage = getLanguage;
var _vue = _interopRequireDefault(require("vue"));
var _vueI18n = _interopRequireDefault(require("vue-i18n"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _en = _interopRequireDefault(require("element-ui/lib/locale/lang/en"));
var _zhCN = _interopRequireDefault(require("element-ui/lib/locale/lang/zh-CN"));
var _es = _interopRequireDefault(require("element-ui/lib/locale/lang/es"));
var _ja = _interopRequireDefault(require("element-ui/lib/locale/lang/ja"));
var _en2 = _interopRequireDefault(require("./en"));
var _zh = _interopRequireDefault(require("./zh"));
var _es2 = _interopRequireDefault(require("./es"));
var _ja2 = _interopRequireDefault(require("./ja"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } // element-ui lang
// element-ui lang
// element-ui lang
// element-ui lang
_vue.default.use(_vueI18n.default);
var messages = {
  en: _objectSpread(_objectSpread({}, _en2.default), _en.default),
  zh: _objectSpread(_objectSpread({}, _zh.default), _zhCN.default),
  es: _objectSpread(_objectSpread({}, _es2.default), _es.default),
  ja: _objectSpread(_objectSpread({}, _ja2.default), _ja.default)
};
function getLanguage() {
  var chooseLanguage = _jsCookie.default.get('language');
  if (chooseLanguage) return chooseLanguage;

  // if has not choose language
  var language = (navigator.language || navigator.browserLanguage).toLowerCase();
  var locales = Object.keys(messages);
  for (var _i = 0, _locales = locales; _i < _locales.length; _i++) {
    var locale = _locales[_i];
    if (language.indexOf(locale) > -1) {
      return locale;
    }
  }
  return 'en';
}
var i18n = new _vueI18n.default({
  // set locale
  // options: en | zh | es
  locale: getLanguage(),
  silentTranslationWarn: true,
  // set locale messages
  messages: messages
});
var _default = exports.default = i18n;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getParamsfield = getParamsfield;
exports.getParamsfield2 = getParamsfield2;
exports.getToken = getToken;
exports.getUserInfo = getUserInfo;
exports.removeParamsfield = removeParamsfield;
exports.removeParamsfield2 = removeParamsfield2;
exports.removeToken = removeToken;
exports.removeUserInfo = removeUserInfo;
exports.setParamsfield = setParamsfield;
exports.setParamsfield2 = setParamsfield2;
exports.setToken = setToken;
exports.setUserInfo = setUserInfo;
var _jsCookie = _interopRequireDefault(require("js-cookie"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var TokenKey = 'Wx-Token';
var UserInfo = 'userinfo';
function getToken() {
  return _jsCookie.default.get(TokenKey);
}
function setToken(token) {
  return _jsCookie.default.set(TokenKey, token);
}
function removeToken() {
  return _jsCookie.default.remove(TokenKey);
}
function getUserInfo() {
  return _jsCookie.default.get(UserInfo);
}
function setUserInfo(token) {
  return _jsCookie.default.set(UserInfo, token);
}
function removeUserInfo() {
  return _jsCookie.default.remove(UserInfo);
}
function getParamsfield(Paramsfield) {
  return _jsCookie.default.get(Paramsfield);
}
function setParamsfield(Paramsfield, token) {
  return _jsCookie.default.set(Paramsfield, token);
}
function removeParamsfield(Paramsfield) {
  return _jsCookie.default.remove(Paramsfield);
}
function getParamsfield2(Paramsfield2) {
  return sessionStorage.getItem(Paramsfield2);
}
function setParamsfield2(Paramsfield2, token) {
  return sessionStorage.setItem(Paramsfield2, token);
}
function removeParamsfield2(Paramsfield2) {
  return sessionStorage.removeItem(Paramsfield2);
}
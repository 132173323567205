"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CurrentCompany = CurrentCompany;
exports.UpdateCompany = UpdateCompany;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
// 列表查询接口
// 获取当前租户的企业信息
function CurrentCompany(data) {
  return (0, _request.default)({
    url: '/api/BaseData/Company/CurrentCompany',
    method: 'get',
    params: data
  });
}
// 修改当前租户的企业信息
function UpdateCompany(data) {
  return (0, _request.default)({
    url: '/api/BaseData/Company/UpdateCompany',
    method: 'put',
    data: data
  });
}
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GetAllCurrentStocks = GetAllCurrentStocks;
exports.GetReportScheduleDetail = GetReportScheduleDetail;
exports.GetTenants = GetTenants;
exports.IsQinFeng = IsQinFeng;
exports.MaterialState = MaterialState;
exports.Processall = Processall;
exports.SaleOrderGetReportScheduleDetail = SaleOrderGetReportScheduleDetail;
exports.SyncU8App = SyncU8App;
exports.commonUsers = commonUsers;
exports.getBrand = getBrand;
exports.getCurrencyType = getCurrencyType;
exports.getDeliveryType = getDeliveryType;
exports.getGetCustomers = getGetCustomers;
exports.getHeatTreatment = getHeatTreatment;
exports.getMDSSupplier = getMDSSupplier;
exports.getMaterial = getMaterial;
exports.getMaterialOutType = getMaterialOutType;
exports.getOtherInType = getOtherInType;
exports.getOtherOutType = getOtherOutType;
exports.getPaymentType = getPaymentType;
exports.getProductInType = getProductInType;
exports.getProject = getProject;
exports.getPurArrivalBusinessType = getPurArrivalBusinessType;
exports.getPurReturnGetListByPage = getPurReturnGetListByPage;
exports.getPurchaseInType = getPurchaseInType;
exports.getPurchaseOutType = getPurchaseOutType;
exports.getPurchaseType = getPurchaseType;
exports.getReturnInType = getReturnInType;
exports.getSaRejectionType = getSaRejectionType;
exports.getSaleOutType = getSaleOutType;
exports.getSaleType = getSaleType;
exports.getSettlementType = getSettlementType;
exports.getShipViaType = getShipViaType;
exports.getSkillTarget = getSkillTarget;
exports.getSurfaceTreatment = getSurfaceTreatment;
exports.getUnit = getUnit;
exports.getWarehouse = getWarehouse;
exports.getallusers = getallusers;
exports.getcustomer = getcustomer;
exports.getloadPosition = getloadPosition;
exports.getorgs = getorgs;
exports.loadWarehousePosition = loadWarehousePosition;
exports.painting = painting;
exports.scanCode = scanCode;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
// 获取基本数据接口汇总
// 获取客户接口
function getcustomer() {
  return (0, _request.default)({
    url: '/api/BaseData/Customer/all',
    method: 'get',
    params: {
      "IsDeleted": false,
      "Enabled": true
    }
  });
}
// 获取租户
function GetTenants() {
  return (0, _request.default)({
    url: '/api/BaseData/Company/GetTenants',
    method: 'get'
  });
}

// 获取物料接口
var tem = {
  "IsDeleted": false,
  "Enabled": true
};
function getMaterial(data) {
  return (0, _request.default)({
    url: '/api/BaseData/Material/all',
    method: 'get',
    params: _objectSpread(_objectSpread({}, tem), data)
  });
}

// 获取热处理接口
function getHeatTreatment() {
  return (0, _request.default)({
    url: '/api/BaseData/HeatTreatment/all',
    method: 'get',
    params: {
      "IsDeleted": false,
      "Enabled": true
    }
  });
}

// 获取表面处理接口
function getSurfaceTreatment() {
  return (0, _request.default)({
    url: '/api/BaseData/SurfaceTreatment/all',
    method: 'get',
    params: {
      "IsDeleted": false,
      "Enabled": true
    }
  });
}

// 获取项目接口
function getProject() {
  return (0, _request.default)({
    url: '/api/BaseData/Project/all',
    method: 'get',
    params: {
      "IsDeleted": false,
      "Enabled": true
    }
  });
}
// 返回所有用户
function getallusers() {
  return (0, _request.default)({
    url: '/api/BaseData/Position/users',
    method: 'get',
    params: {
      "IsDeleted": false,
      "Enabled": true
    }
  });
}

// 销售订单业务类型
function getSaleType() {
  return (0, _request.default)({
    url: '/api/BaseData/SaleType/all',
    method: 'get',
    params: {
      "IsDeleted": false,
      "Enabled": true
    }
  });
}

// 币种
function getCurrencyType() {
  return (0, _request.default)({
    url: '/api/BaseData/CurrencyType/all',
    method: 'get',
    params: {
      "IsDeleted": false,
      "Enabled": true
    }
  });
}

// 计量单位
function getUnit() {
  return (0, _request.default)({
    url: '/api/BaseData/Unit/all',
    method: 'get',
    params: {
      "IsDeleted": false,
      "Enabled": true
    }
  });
}

// 部门
function getorgs(data) {
  return (0, _request.default)({
    url: '/api/base/orgs/loadOrgs',
    method: 'get',
    params: data
  });
}

// 其他出库类型列表查询接口
function getOtherOutType() {
  return (0, _request.default)({
    url: '/api/BaseData/OtherOutType/all',
    method: 'get',
    params: {
      "IsDeleted": false,
      "Enabled": true
    }
  });
}

// 仓库列表查询
function getWarehouse() {
  return (0, _request.default)({
    url: '/api/BaseData/Warehouse/all',
    method: 'get',
    params: {
      "IsDeleted": false,
      "Enabled": true
    }
  });
}

// 其他入库类型
function getOtherInType() {
  return (0, _request.default)({
    url: '/api/BaseData/OtherInType/all',
    method: 'get',
    params: {
      "IsDeleted": false,
      "Enabled": true
    }
  });
}

// 获取子货位
function getloadPosition() {
  return (0, _request.default)({
    url: '/api/BaseData/Position/loadPosition',
    method: 'get',
    params: {
      "IsDeleted": false,
      "Enabled": true
    }
  });
}

// 获取仓库对应的货位
function loadWarehousePosition(data) {
  return (0, _request.default)({
    url: '/api/BaseData/Position/loadWarehousePosition',
    method: 'get',
    params: data
  });
}

// 退库入库类型
function getReturnInType() {
  return (0, _request.default)({
    url: '/api/BaseData/ReturnInType/all',
    method: 'get',
    params: {
      "IsDeleted": false,
      "Enabled": true
    }
  });
}

// 材料出库业务类型
function getMaterialOutType() {
  return (0, _request.default)({
    url: '/api/BaseData/MaterialOutType/all',
    method: 'get',
    params: {
      "IsDeleted": false,
      "Enabled": true
    }
  });
}
// 采购入库类型
function getPurchaseType() {
  return (0, _request.default)({
    url: '/api/BaseData/PurchaseType/all',
    method: 'get',
    params: {
      "IsDeleted": false,
      "Enabled": true
    }
  });
}
// 供应商
function getMDSSupplier() {
  return (0, _request.default)({
    url: '/api/BaseData/MDS_Supplier/all',
    method: 'get',
    params: {
      "IsDeleted": false,
      "Enabled": true
    }
  });
}
// 付款方式
function getPaymentType() {
  return (0, _request.default)({
    url: '/api/BaseData/PaymentType/all',
    method: 'get',
    params: {
      "IsDeleted": false,
      "Enabled": true
    }
  });
}
// 发运方式
function getShipViaType() {
  return (0, _request.default)({
    url: '/api/BaseData/ShipViaType/all',
    method: 'get',
    params: {
      "IsDeleted": false,
      "Enabled": true
    }
  });
}
// 结算方式
function getSettlementType() {
  return (0, _request.default)({
    url: '/api/BaseData/SettlementType/all',
    method: 'get',
    params: {
      "IsDeleted": false,
      "Enabled": true
    }
  });
}
function getProductInType() {
  return (0, _request.default)({
    url: '/api/BaseData/ProductInType/all',
    method: 'get',
    params: {
      "IsDeleted": false,
      "Enabled": true
    }
  });
}
// 销售发货业务类型
function getDeliveryType() {
  return (0, _request.default)({
    url: '/api/BaseData/DeliveryType/all',
    method: 'get',
    params: {
      "IsDeleted": false,
      "Enabled": true
    }
  });
}

// 销售出库类型
function getSaleOutType() {
  return (0, _request.default)({
    url: '/api/BaseData/SaleOutType/all',
    method: 'get',
    params: {
      "IsDeleted": false,
      "Enabled": true
    }
  });
}

// 退货出库类型
function getPurchaseOutType() {
  return (0, _request.default)({
    url: '/api/BaseData/PurchaseOutType/all',
    method: 'get',
    params: {
      "IsDeleted": false,
      "Enabled": true
    }
  });
}

// 采购入库类型
function getPurchaseInType() {
  return (0, _request.default)({
    url: '/api/BaseData/PurchaseInType/all',
    method: 'get',
    params: {
      "IsDeleted": false,
      "Enabled": true
    }
  });
}
//  销售拒收业务类型
function getSaRejectionType() {
  return (0, _request.default)({
    url: '/api/BaseData/SaRejectionType/all',
    method: 'get',
    params: {
      "IsDeleted": false,
      "Enabled": true
    }
  });
}

// 引用采购退货单
function getPurReturnGetListByPage(data) {
  return (0, _request.default)({
    url: '/api/SalesManagement/PurReturn/GetListByPage',
    method: 'get',
    params: data
  });
}

// 工艺接口
function Processall() {
  return (0, _request.default)({
    url: '/api/BaseData/Process/all',
    method: 'get',
    params: {
      "IsDeleted": false,
      "Enabled": true
    }
  });
}

// 品牌
function getBrand() {
  return (0, _request.default)({
    url: '/api/BaseData/Brand/all',
    method: 'get',
    params: {
      "IsDeleted": false,
      "Enabled": true
    }
  });
}

// 采购到货单业务类型
function getPurArrivalBusinessType() {
  return (0, _request.default)({
    url: '/api/BaseData/PurArrivalBusinessType/all',
    method: 'get',
    params: {
      "IsDeleted": false,
      "Enabled": true
    }
  });
}

// 技术指标
function getSkillTarget() {
  return (0, _request.default)({
    url: '/api/BaseData/SkillTarget/all',
    method: 'get',
    params: {
      "IsDeleted": false,
      "Enabled": true
    }
  });
}

// 获取所有的客户(是否存在外协关系)
function getGetCustomers() {
  return (0, _request.default)({
    url: '/api/BaseData/Company/GetCustomers',
    method: 'get'
  });
}

// 采购进度详情
function GetReportScheduleDetail(data) {
  return (0, _request.default)({
    url: '/api/SalesManagement/PurchaseOrder/GetReportScheduleDetail',
    method: 'get',
    params: data
  });
}

// 销售进度详情
function SaleOrderGetReportScheduleDetail(data) {
  return (0, _request.default)({
    url: '/api/SalesManagement/SaleOrder/GetReportScheduleDetail',
    method: 'get',
    params: data
  });
}

// 获取当前用户信息
function commonUsers() {
  return (0, _request.default)({
    url: '/api/BaseData/Common/users',
    method: 'get'
  });
}

// 现存量接口查询
function GetAllCurrentStocks(data) {
  return (0, _request.default)({
    url: '/api/BaseData/CurrentStock/GetAllCurrentStocks',
    method: 'get',
    params: data
  });
}

// 是否勤丰
function IsQinFeng() {
  return (0, _request.default)({
    url: '/api/BaseData/Common/IsQinFeng',
    method: 'get'
  });
}

//  提料扫码
function MaterialState(data) {
  return (0, _request.default)({
    url: '/api/BaseData/MaterialState',
    method: 'post',
    data: data
  });
}
function painting(data) {
  return (0, _request.default)({
    url: '/api/BaseData/MaterialState/Painting',
    method: 'get',
    params: data
  });
}

//  扫码
function scanCode(data) {
  return (0, _request.default)({
    url: '/api/SalesManagement/Common',
    method: 'get',
    params: data
  });
}

//  同步用友
function SyncU8App(data) {
  return (0, _request.default)({
    url: '/api/SalesManagement/SyncU8App',
    method: 'post',
    data: data
  });
}
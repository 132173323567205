"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'RightPanel',
  props: {
    clickNotClose: {
      default: false,
      type: Boolean
    },
    buttonTop: {
      default: 100,
      type: Number
    }
  },
  data: function data() {
    return {
      show: false,
      buttonTop1: 350,
      DownPageY: 0,
      UpPageY: 0
    };
  },
  computed: {
    theme: function theme() {
      return this.$store.state.settings.theme;
    }
  },
  watch: {
    show: function show(value) {
      if (value && !this.clickNotClose) {
        this.addEventClick();
      }
      if (value) {
        (0, _utils.addClass)(document.body, 'showRightPanel');
      } else {
        (0, _utils.removeClass)(document.body, 'showRightPanel');
      }
    }
  },
  mounted: function mounted() {
    this.insertToBody();
    // 读取移动位置
    if (localStorage.getItem('RightPanelButtonTop')) {
      this.buttonTop1 = localStorage.getItem('RightPanelButtonTop');
    }
  },
  beforeDestroy: function beforeDestroy() {
    var elx = this.$refs.rightPanel;
    elx.remove();
  },
  methods: {
    panelClick: function panelClick(e) {
      if (this.DownPageY == this.UpPageY) {
        this.show = !this.show;
      }
    },
    panelMouseDown: function panelMouseDown(e) {
      e.preventDefault();
      this.DownPageY = e.pageY;
      document.addEventListener('mousemove', this.handleMousemove);
      document.addEventListener('mouseup', this.handleMouseup);
    },
    handleMousemove: function handleMousemove(e) {
      e.preventDefault();
      this.buttonTop1 = e.pageY - 20;
    },
    handleMouseup: function handleMouseup(event) {
      // 记录移动位置
      localStorage.setItem('RightPanelButtonTop', this.buttonTop1);
      this.UpPageY = event.pageY;
      event.stopPropagation();
      document.removeEventListener('mousemove', this.handleMousemove);
      document.removeEventListener('mouseup', this.handleMouseup);
    },
    addEventClick: function addEventClick() {
      window.addEventListener('click', this.closeSidebar);
    },
    closeSidebar: function closeSidebar(evt) {
      var parent = evt.target.closest('.rightPanel');
      if (!parent) {
        this.show = false;
        window.removeEventListener('click', this.closeSidebar);
      }
    },
    insertToBody: function insertToBody() {
      var elx = this.$refs.rightPanel;
      var body = document.querySelector('body');
      body.insertBefore(elx, body.firstChild);
    }
  }
};
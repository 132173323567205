"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.loadView = exports.default = void 0;
var _router = require("@/router");
var _index = _interopRequireDefault(require("@/layout/index"));
var _index2 = _interopRequireDefault(require("../../axios/index.js"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var permission = {
  state: {
    routes: [],
    addRoutes: []
  },
  mutations: {
    SET_ROUTES: function SET_ROUTES(state, routes) {
      state.addRoutes = routes;
      state.routes = _router.constantRoutes.concat(routes);
    }
  },
  actions: {
    // 生成路由
    GenerateRoutes: function GenerateRoutes(_ref) {
      var commit = _ref.commit;
      return new Promise(function (resolve) {
        // 获取角色菜单
        _index2.default.getMenus('/api/base/role-menus').then(function (res) {
          var accessedRoutes = filterAsyncRouter(res.response.items);
          accessedRoutes.push({
            path: '*',
            redirect: '/404',
            hidden: true
          });
          commit('SET_ROUTES', accessedRoutes);
          resolve(accessedRoutes);
        });
      });
    }
  }
};

// 遍历后台传来的路由字符串，转换为组件对象
function filterAsyncRouter(asyncRouterMap) {
  return asyncRouterMap.filter(function (route) {
    if (route.component) {
      // Layout组件特殊处理
      if (route.component === 'Layout') {
        route.component = _index.default;
      } else {
        route.component = loadView(route.component);
      }
    }
    if (route.children != null && route.children && route.children.length) {
      route.children = filterAsyncRouter(route.children);
    } else {
      delete route.children;
    }
    return true;
  });
}
var loadView = exports.loadView = function loadView(view) {
  // 路由懒加载
  return function (resolve) {
    return require(["@/views/".concat(view)], resolve);
  };
};
var _default = exports.default = permission;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    user: {
      type: Object
    }
  },
  data: function data() {
    return {
      // 表单校验
      rules: {
        nickName: [{
          required: true,
          message: "用户昵称不能为空",
          trigger: "blur"
        }],
        email: [{
          required: true,
          message: "邮箱地址不能为空",
          trigger: "blur"
        }, {
          type: "email",
          message: "'请输入正确的邮箱地址",
          trigger: ["blur", "change"]
        }],
        phonenumber: [{
          required: true,
          message: "手机号码不能为空",
          trigger: "blur"
        }, {
          pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
          message: "请输入正确的手机号码",
          trigger: "blur"
        }]
      }
    };
  },
  methods: {
    submit: function submit() {
      var _this = this;
      this.$refs["form"].validate(function (valid) {
        if (valid) {
          _this.$axios.puts("/api/account/my-profile", _this.user).then(function (response) {
            _this.$notify({
              title: "成功",
              message: "修改成功",
              type: "success",
              duration: 60000
            });
          }).catch(function () {
            //
          });
        }
      });
    },
    close: function close() {
      this.$store.dispatch("tagsView/delView", this.$route);
      this.$router.push({
        path: "/dashboard"
      });
    }
  }
};
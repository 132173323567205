"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _router = _interopRequireDefault(require("../router"));
var _axios = _interopRequireDefault(require("axios"));
var _qs = _interopRequireDefault(require("qs"));
var _config = _interopRequireDefault(require("../../static/config"));
var _elementUi = require("element-ui");
var _auth = require("@/utils/auth");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
_axios.default.defaults.timeout = 600000;
_axios.default.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8;';
//TODO:配置读取
_axios.default.defaults.headers['Accept-Language'] = "zh-Hans";
// axios.defaults.baseURL = '';
_axios.default.defaults.baseURL = _config.default.base.ip;
// POST传参序列化
_axios.default.interceptors.request.use(function (config) {
  // eslint-disable-next-line eqeqeq
  if ((0, _auth.getToken)() != '') {
    config.headers.Authorization = 'Bearer ' + (0, _auth.getToken)();
  } else {
    _router.default.replace({
      path: '/login'
    });
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

// 返回状态判断
_axios.default.interceptors.response.use(function (res) {
  // return Promise.reject(res);
  return res;
}, function (err) {
  // 404等问题可以在这里处理
  if (err.response) {
    var error = err.error = {};
    switch (err.response.status) {
      // 判断后台返回的token值是否失效
      case 401:
        (0, _elementUi.Message)({
          message: '登录过期，请重新登录！',
          type: 'error',
          duration: 5 * 1000
        });
        _router.default.replace({
          path: '/login'
        });
        return;
      case 400:
        error.code = err.response.data.error.code;
        error.message = err.response.data.error.message;
        error.details = err.response.data.error.details;
        break;
      case 403:
        error.code = err.response.data.error.code;
        error.message = err.response.data.error.message;
        error.details = err.response.data.error.details;
        break;
      case 404:
        if (!err.response.data) {
          error.code = '未找到服务！';
        } else {
          error.code = err.response.data.error.code;
          error.message = err.response.data.error.message;
          error.details = err.response.data.error.details;
        }
        break;
      case 408:
        error.code = err.response.data.error.code;
        error.message = err.response.data.error.message;
        error.details = err.response.data.error.details;
        break;
      case 500:
        error.code = err.response.data.error.code;
        error.message = err.response.data.error.message;
        error.details = err.response.data.error.details;
        break;
      case 501:
        error.code = err.response.data.error.code;
        error.message = err.response.data.error.message;
        error.details = err.response.data.error.details;
        break;
      case 502:
        error.code = "502 Bad Gateway";
        break;
      case 503:
        error.code = err.response.data.error.code;
        error.message = err.response.data.error.message;
        error.details = err.response.data.error.details;
        break;
      case 504:
        error.code = err.response.data.error.code;
        error.message = err.response.data.error.message;
        error.details = err.response.data.error.details;
        break;
      case 505:
        error.code = err.response.data.error.code;
        error.message = err.response.data.error.message;
        error.details = err.response.data.error.details;
        break;
      default:
    }
    return Promise.reject(err);
  } else if (err.request) {
    return Promise.reject(err.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    return Promise.reject('Error', err.message);
  }
});
var _default = exports.default = {
  posts: function posts(url, params) {
    return new Promise(function (resolve, reject) {
      _axios.default.post(url, params).then(function (response) {
        resolve(response.data);
      }, function (err) {
        (0, _elementUi.Message)({
          message: !err.error.code ? err.error.message : err.error.code,
          type: 'error',
          duration: 5 * 1000
        });
        reject(err);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  gets: function gets(url, params) {
    return new Promise(function (resolve, reject) {
      _axios.default.get(url, {
        'params': params
      }).then(function (response) {
        resolve(response.data);
      }, function (err) {
        (0, _elementUi.Message)({
          message: !err.error.code ? err.error.message : err.error.code,
          type: 'error',
          duration: 5 * 1000
        });
        reject(err);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  deletes: function deletes(url, params) {
    return new Promise(function (resolve, reject) {
      _axios.default.delete(url, {
        'params': params
      }).then(function (response) {
        resolve(response.data);
      }, function (err) {
        (0, _elementUi.Message)({
          message: !err.error.code ? err.error.message : err.error.code,
          type: 'error',
          duration: 5 * 1000
        });
        reject(err);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  puts: function puts(url, params) {
    return new Promise(function (resolve, reject) {
      _axios.default.put(url, params).then(function (response) {
        resolve(response.data);
      }, function (err) {
        (0, _elementUi.Message)({
          message: !err.error.code ? err.error.message : err.error.code,
          type: 'error',
          duration: 5 * 1000
        });
        reject(err);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  downLoad: function downLoad(url, params) {
    var instance = _axios.default.create({
      responseType: 'blob'
    });
    instance.defaults.headers.Authorization = 'Bearer ' + (0, _auth.getToken)();
    instance.defaults.headers['Accept-Language'] = "zh-Hans";
    return new Promise(function (resolve, reject) {
      instance.get(url, {
        'params': params
      }).then(function (response) {
        resolve(response);
      }, function (err) {
        reject(err);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  instancePosts: function instancePosts(url, params) {
    // 登录
    var instance = _axios.default.create({
      baseURL: _config.default.auth.ip
    });
    if (params.tenant && params.tenant.trim() != '') {
      url = url + "?__tenant=" + params.tenant;
    }
    var data = _qs.default.stringify(params);
    return new Promise(function (resolve, reject) {
      instance.post(url, data).then(function (response) {
        resolve(response.data);
      }, function (err) {
        if (err.response.status === 400) {
          (0, _elementUi.Message)({
            message: '用户名或密码错误',
            type: 'error',
            duration: 5 * 1000
          });
        } else {
          (0, _elementUi.Message)({
            message: err.message,
            type: 'error',
            duration: 5 * 1000
          });
        }
        reject(err);
      }).catch(function (error) {
        (0, _elementUi.Message)({
          message: '登录异常',
          type: 'error',
          duration: 5 * 1000
        });
        reject(error);
      });
    });
  },
  getUserInfo: function getUserInfo(url) {
    // 获取用户信息
    var instance = _axios.default.create({
      baseURL: _config.default.auth.ip
    });
    instance.defaults.headers.Authorization = 'Bearer ' + (0, _auth.getToken)();
    return new Promise(function (resolve, reject) {
      instance.get(url).then(function (response) {
        resolve(response.data);
      }, function (err) {
        (0, _elementUi.Message)({
          message: err.message,
          type: 'error',
          duration: 5 * 1000
        });
        reject(err);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  getPermissions: function getPermissions(url, params) {
    var instance = _axios.default.create({
      baseURL: _config.default.base.ip
    });
    instance.defaults.headers.Authorization = 'Bearer ' + (0, _auth.getToken)();
    return new Promise(function (resolve, reject) {
      instance.get(url, {
        'params': params,
        headers: {
          "wrap-result": true
        }
      }).then(function (response) {
        resolve(response.data);
      }, function (err) {
        (0, _elementUi.Message)({
          message: err.message,
          type: 'error',
          duration: 5 * 1000
        });
        reject(err);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  getMenus: function getMenus(url, params) {
    return new Promise(function (resolve, reject) {
      _axios.default.get(url, {
        'params': params,
        headers: {
          "wrap-result": true
        }
      }).then(function (response) {
        resolve(response.data);
      }, function (err) {
        (0, _elementUi.Message)({
          message: err.message,
          type: 'error',
          duration: 5 * 1000
        });
        reject(err);
      }).catch(function (error) {
        reject(error);
      });
    });
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login" },
    [
      _c(
        "el-form",
        {
          ref: "loginForm",
          staticClass: "login-form",
          attrs: {
            model: _vm.loginForm,
            rules: _vm.loginRules,
            "label-position": "left",
            "label-width": "0px",
          },
        },
        [
          _c("h3", { staticClass: "title" }, [_vm._v("外协平台登录")]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "tenant" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    filterable: "",
                    remote: "",
                    clearable: "",
                    "reserve-keyword": "",
                    placeholder: "公司",
                    "remote-method": _vm.remoteMethod,
                    loading: _vm.loading,
                  },
                  model: {
                    value: _vm.loginForm.tenant,
                    callback: function ($$v) {
                      _vm.$set(_vm.loginForm, "tenant", $$v)
                    },
                    expression: "loginForm.tenant",
                  },
                },
                _vm._l(_vm.options, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "username" } },
            [
              _c("el-input", {
                attrs: {
                  clearable: "",
                  type: "text",
                  "auto-complete": "off",
                  placeholder: "账号",
                },
                model: {
                  value: _vm.loginForm.username,
                  callback: function ($$v) {
                    _vm.$set(_vm.loginForm, "username", $$v)
                  },
                  expression: "loginForm.username",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "password" } },
            [
              _c("el-input", {
                attrs: {
                  clearable: "",
                  type: "password",
                  "auto-complete": "off",
                  placeholder: "密码",
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleLogin($event)
                  },
                },
                model: {
                  value: _vm.loginForm.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.loginForm, "password", $$v)
                  },
                  expression: "loginForm.password",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticStyle: { width: "100%" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    loading: _vm.loading,
                    size: "medium",
                    type: "primary",
                  },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.handleLogin($event)
                    },
                  },
                },
                [
                  !_vm.loading
                    ? _c("span", [_vm._v("登 录")])
                    : _c("span", [_vm._v("登 录 中...")]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
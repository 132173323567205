"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getInfo = getInfo;
exports.login = login;
exports.logout = logout;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function login(data) {
  return (0, _request.default)({
    url: '/vue-element-admin/user/login',
    method: 'post',
    data: data
  });
}
function getInfo(token) {
  return (0, _request.default)({
    url: '/vue-element-admin/user/info',
    method: 'get',
    params: {
      token: token
    }
  });
}
function logout() {
  return (0, _request.default)({
    url: '/vue-element-admin/user/logout',
    method: 'post'
  });
}
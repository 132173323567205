"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vanillaTilt = _interopRequireDefault(require("vanilla-tilt"));
var _LangSelect = _interopRequireDefault(require("@/components/LangSelect"));
var _SocialSignin = _interopRequireDefault(require("./components/SocialSignin"));
var _config = _interopRequireDefault(require("../../../static/config"));
var _base = require("@/api/base");
var _auth = require("@/utils/auth");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Login',
  components: {
    LangSelect: _LangSelect.default,
    SocialSign: _SocialSignin.default
  },
  data: function data() {
    var validatePassword = function validatePassword(rule, value, callback) {
      if (value.length < 6) {
        callback(new Error('The password can not be less than 6 digits'));
      } else {
        callback();
      }
    };
    return {
      loginForm: {
        tenant: undefined,
        username: '',
        password: '',
        client_id: _config.default.client.client_id,
        grant_type: _config.default.client.grant_type,
        scope: _config.default.client.scope
      },
      loginRules: {
        username: [{
          required: true,
          message: 'username is required',
          trigger: 'blur'
        }],
        password: [{
          required: true,
          trigger: 'blur',
          validator: validatePassword
        }]
      },
      passwordType: 'password',
      capsTooltip: false,
      loading: false,
      showDialog: false,
      redirect: undefined,
      otherQuery: {},
      options: [],
      list: []
    };
  },
  watch: {
    $route: {
      handler: function handler(route) {
        var query = route.query;
        if (query) {
          this.redirect = query.redirect;
          this.otherQuery = this.getOtherQuery(query);
        }
      },
      immediate: true
    }
  },
  created: function created() {
    this.getGetTenants();
    // window.addEventListener('storage', this.afterQRScan)
    setTimeout(function () {
      _vanillaTilt.default.init(document.querySelectorAll('.card'), {
        max: 25,
        speed: 400,
        glare: true,
        'max-glare': 1
      });
    }, 2000);
  },
  destroyed: function destroyed() {
    // window.removeEventListener('storage', this.afterQRScan)
  },
  methods: {
    remoteMethod: function remoteMethod(query) {
      var _this = this;
      if (query !== '') {
        this.loading = true;
        this.getGetTenants();
        setTimeout(function () {
          _this.loading = false;
          _this.options = _this.list.filter(function (item) {
            return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.options = [];
      }
    },
    getGetTenants: function getGetTenants() {
      var _this2 = this;
      // console.log('%c [  ]-121', 'font-size:13px; background:pink; color:#bf2c9f;');
      // const loading = this.$loading({
      // 	lock: true,
      // 	text: '请等待...',
      // 	spinner: 'el-icon-loading',
      // 	background: 'rgba(0, 0, 0, 0.7)'
      // });
      (0, _base.GetTenants)().then(function (res) {
        // loading.close();
        _this2.list = res.map(function (item) {
          return {
            label: item,
            value: item
          };
        });
      }).catch(function () {
        // loading.close();
      });
    },
    checkCapslock: function checkCapslock(e) {
      var key = e.key;
      this.capsTooltip = key && key.length === 1 && key >= 'A' && key <= 'Z';
    },
    showPwd: function showPwd() {
      var _this3 = this;
      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }
      this.$nextTick(function () {
        _this3.$refs.password.focus();
      });
    },
    handleLogin: function handleLogin() {
      var _this4 = this;
      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          _this4.loading = true;
          _this4.$store.dispatch('user/userLogin', _this4.loginForm).then(function () {
            _this4.$router.push({
              // path: this.redirect || "/",
              path: '/',
              query: _this4.otherQuery
            });
            _this4.loading = false;
            (0, _base.commonUsers)().then(function (res) {
              (0, _base.IsQinFeng)().then(function (response) {
                res[0].isQinFeng = response;
                (0, _auth.setUserInfo)(res[0]);
              });
            });
          }).catch(function () {
            _this4.loading = false;
          });
        } else {
          return false;
        }
      });
    },
    getOtherQuery: function getOtherQuery(query) {
      return Object.keys(query).reduce(function (acc, cur) {
        if (cur !== 'redirect') {
          acc[cur] = query[cur];
        }
        return acc;
      }, {});
    }
  }
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.loadView = exports.default = void 0;
var _user = require("@/api/user");
var _auth = require("@/utils/auth");
var _router = require("@/router");
var _index = _interopRequireDefault(require("@/layout/index"));
var _index2 = _interopRequireDefault(require("../../axios/index.js"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var state = {
  token: (0, _auth.getToken)(),
  name: '',
  avatar: '',
  introduction: '',
  roles: [],
  routes: [],
  addRoutes: []
};
var mutations = {
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_INTRODUCTION: function SET_INTRODUCTION(state, introduction) {
    state.introduction = introduction;
  },
  SET_NAME: function SET_NAME(state, name) {
    state.name = name;
  },
  SET_AVATAR: function SET_AVATAR(state, avatar) {
    state.avatar = avatar;
  },
  SET_ROLES: function SET_ROLES(state, roles) {
    state.roles = roles;
  },
  SET_ROUTES: function SET_ROUTES(state, routes) {
    state.addRoutes = routes;
    state.routes = _router.constantRoutes.concat(routes);
  },
  SET_PERMISSIONS: function SET_PERMISSIONS(state, permissions) {
    state.permissions = permissions;
  }
};
var actions = {
  // user login
  login: function login(_ref, userInfo) {
    var commit = _ref.commit;
    var username = userInfo.username,
      password = userInfo.password;
    return new Promise(function (resolve, reject) {
      (0, _user.login)({
        username: username.trim(),
        password: password
      }).then(function (response) {
        var data = response.data;
        commit('SET_TOKEN', data.token);
        (0, _auth.setToken)(data.token);
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  userLogin: function userLogin(_ref2, data) {
    var commit = _ref2.commit;
    // 用户登录
    return new Promise(function (resolve, reject) {
      _index2.default.instancePosts('/connect/token', data).then(function (response) {
        commit('SET_TOKEN', response.access_token);
        (0, _auth.setToken)(response.access_token);
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // get user info
  getInfo: function getInfo(_ref3) {
    var commit = _ref3.commit;
    return new Promise(function (resolve, reject) {
      _index2.default.getUserInfo('/connect/userinfo').then(function (response) {
        commit('SET_NAME', response.name);
        commit('SET_SUB', response.sub);
        resolve(response);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  getPermissions: function getPermissions(_ref4, role) {
    var commit = _ref4.commit;
    return new Promise(function (resolve, reject) {
      _index2.default.getPermissions('/api/abp/application-configuration').then(function (response) {
        if (response.status == 200 && response.success) {
          var data = {};
          data.roles = [];
          _index2.default.getPermissions('/api/base/role-menus/Permission').then(function (res) {
            for (var role in res.response.grantedPolicies) {
              data.roles.push(role);
            }
            if (!data.roles || data.roles.length <= 0) {
              reject('该用户没有任何权限！');
            }
            commit('SET_ROLES', data.roles);
            resolve(data);
          });
        } else {
          reject('登录失败');
        }
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // user logout
  logout: function logout(_ref5) {
    var commit = _ref5.commit,
      state = _ref5.state,
      dispatch = _ref5.dispatch;
    return new Promise(function (resolve, reject) {
      commit('SET_TOKEN', '');
      commit('SET_ROLES', []);
      (0, _auth.removeToken)();
      (0, _router.resetRouter)();

      // reset visited views and cached views
      // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485
      dispatch('tagsView/delAllViews', null, {
        root: true
      });
      resolve();
    });
  },
  // remove token
  resetToken: function resetToken(_ref6) {
    var commit = _ref6.commit;
    return new Promise(function (resolve) {
      commit('SET_TOKEN', '');
      commit('SET_ROLES', []);
      (0, _auth.removeToken)();
      resolve();
    });
  },
  GenerateRoutes: function GenerateRoutes(_ref7) {
    var commit = _ref7.commit;
    return new Promise(function (resolve, reject) {
      _index2.default.getMenus('/api/base/role-menus').then(function (response) {
        var accessedRoutes = filterAsyncRouter(response.response.items);
        accessedRoutes.push({
          path: '*',
          redirect: '/404',
          hidden: true
        });
        commit('SET_ROUTES', accessedRoutes);
        console.log('%c [ accessedRoutes ]-142', 'font-size:13px; background:pink; color:#bf2c9f;', accessedRoutes);
        resolve(accessedRoutes);
      }).catch(function (error) {
        reject(error);
      });
    });
  }
};
function filterAsyncRouter(asyncRouterMap) {
  return asyncRouterMap.filter(function (route) {
    if (route.component) {
      // Layout组件特殊处理
      if (route.component === 'Layout') {
        route.component = _index.default;
      } else {
        route.component = loadView(route.component);
      }
    }
    if (route.children != null && route.children && route.children.length) {
      route.children = filterAsyncRouter(route.children);
    }
    return true;
  });
}
var loadView = exports.loadView = function loadView(view) {
  // 路由懒加载
  return function (resolve) {
    return require(["@/views/".concat(view)], resolve);
  };
};
var _default = exports.default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.searchUser = searchUser;
exports.transactionList = transactionList;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function searchUser(name) {
  return (0, _request.default)({
    url: '/vue-element-admin/search/user',
    method: 'get',
    params: {
      name: name
    }
  });
}
function transactionList(query) {
  return (0, _request.default)({
    url: '/vue-element-admin/transaction/list',
    method: 'get',
    params: query
  });
}
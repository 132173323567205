"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
var _vue = _interopRequireDefault(require("vue"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _umyUi = _interopRequireDefault(require("umy-ui"));
require("umy-ui/lib/theme-chalk/index.css");
var _printJs = _interopRequireDefault(require("print-js"));
require("normalize.css/normalize.css");
var _elementUi = _interopRequireDefault(require("element-ui"));
require("./styles/element-variables.scss");
require("@/styles/index.scss");
var _App = _interopRequireDefault(require("./App"));
var _store = _interopRequireDefault(require("./store"));
var _router = _interopRequireDefault(require("./router"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _lang = _interopRequireDefault(require("./lang"));
require("./icons");
require("./permission");
require("./utils/error-log");
var _axios = _interopRequireDefault(require("./axios"));
var _moment = _interopRequireDefault(require("moment"));
var filters = _interopRequireWildcard(require("./filters"));
require("../src/style/bianse.css");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
// 引入样式

// a modern alternative to CSS resets

// global css

// internationalization
// icon
// permission control
// error log

// 导入共用组件

// global filters

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online ! ! !
 */
// if (process.env.NODE_ENV === 'production') {
// const { mockXHR } = require('../mock')
// mockXHR()
// }

_vue.default.use(_elementUi.default, {
  size: _jsCookie.default.get('size') || 'medium',
  // set element-ui default size
  i18n: function i18n(key, value) {
    return _lang.default.t(key, value);
  }
});
_vue.default.component('Pagination', _Pagination.default);
_vue.default.prototype.tableRowClass = function (row) {
  if (row.rowIndex % 2 == 0) {
    return 'rowstyle';
  } else {
    return 'rowstyle1';
  }
};
// register global utility filters
Object.keys(filters).forEach(function (key) {
  _vue.default.filter(key, filters[key]);
});
_vue.default.filter('formatDate', function (value) {
  if (value) {
    return (0, _moment.default)(String(value)).format('YYYY-MM-DD');
  }
});
_vue.default.filter('formatDateTime', function (value) {
  if (value) {
    return (0, _moment.default)(String(value)).format('YYYY-MM-DD hh:mm:ss');
  }
});
_vue.default.filter('displayWorkflowStatus', function (value) {
  if (value == 0) {
    return '未审核';
  }
  if (value == 1) {
    return '审核中';
  }
  if (value == 2) {
    return '已审核';
  }
});
_vue.default.filter('workflowStatusFilter', function (value) {
  if (value == 0) {
    return 'info';
  } else if (value == 2) {
    return 'success';
  } else {
    return 'warning';
  }
});
_vue.default.use(_umyUi.default);
_vue.default.prototype.$axios = _axios.default;
_vue.default.config.productionTip = false;
var todaydata = new Date();
var year = todaydata.getFullYear();
var month = todaydata.getMonth() + 1;
var day = todaydata.getDate();
var today = year + '-' + month + '-' + day;
_vue.default.prototype.$today = today;
new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  i18n: _lang.default,
  render: function render(h) {
    return h(_App.default);
  }
});
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _userInfo = _interopRequireDefault(require("./userInfo"));
var _resetPwd = _interopRequireDefault(require("./resetPwd"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "Profile",
  components: {
    userInfo: _userInfo.default,
    resetPwd: _resetPwd.default
  },
  data: function data() {
    return {
      user: {},
      roleGroup: {},
      postGroup: {},
      activeTab: "userinfo"
    };
  },
  created: function created() {
    this.getUser();
  },
  methods: {
    getUser: function getUser() {
      var _this = this;
      this.$axios.gets("/api/account/my-profile").then(function (response) {
        _this.user = response;
        //this.roleGroup = response.roleGroup;
        //this.postGroup = response.postGroup;
      });
    }
  }
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = getPageTitle;
var _settings = _interopRequireDefault(require("@/settings"));
var _lang = _interopRequireDefault(require("@/lang"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var title = _settings.default.title || 'ABP-Admin';
function getPageTitle(key) {
  var hasKey = _lang.default.te("route.".concat(key));
  if (hasKey) {
    var pageName = _lang.default.t("route.".concat(key));
    return "".concat(pageName, " - ").concat(title);
  }
  return "".concat(title);
}